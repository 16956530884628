<template>
    <div class="message-page">
        <router-link class="back-btn" to="/agentlist"><i class="iconfont icon-lujing"></i></router-link>
        <div class="loading" v-if="loading">
            <div class="loader"></div><i class="iconfont icon-hudongquwei"></i>
        </div>
        <div class="agent-panel" v-if="agent">
            <div class="avatar">
                <img :src="agent.avatar">
            </div>
            <div class="agent">
                <div class="name">{{agent.name}}</div>
                <div class="tips">最专业的分析建议，给到您</div>
            </div>
        </div>
        <div class="msg-list">
            <div class="item" v-for="item in list" :key="item.id">
                <div class="question">
                    <span>问</span>
                    {{ item.content }}
                </div>
                <div class="answer" v-if="item.answer">
                    <span>答</span>
                    <div class="content">
                        <div class="time">{{ item.updated_at }}</div>
                        <div class="box">
                            {{ item.answer }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form" v-if="agent">
            <div class="col form-group">
                <label for="">姓名</label>
                <input type="text" class="name" v-model="message.name">
                <label for="">电话</label>
                <input type="tel" class="phone" v-model="message.phone">
            </div>
            <div class="col">
                <textarea name="msg" rows="5" placeholder="在线咨询..." v-model="message.content"></textarea>
            </div>
            <div class="col text-center">
                <a href="javascript:;" class="post-btn" @click="post">发送咨询</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Message",
    data(){
        return{
            loading: true,
            agent: null,
            list: [],
            ispost: false,
            message: {
                name: '',
                phone: '',
                content: ''
            }
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            this.$http.get('user/messagelist/'+this.$route.params.id).then((response)=>{
                console.log(response)
                this.loading = false
                this.agent = response.data.agent
                this.list = response.data.list.data
            })
        },
        post(){
            if(this.ispost)return
            this.ispost = true
            this.$http.post('user/message/'+this.$route.params.id,{name:this.message.name,phone:this.message.phone,content:this.message.content}).then((response)=>{
                this.ispost = false
                console.log('response',response)
                this.getData()
                this.message.content = ''
            }).catch((error) => {
				console.log('error',error)
				this.ispost = false
				this.$toast(error.data.error)
			})
        }
    }
}
</script>
<style lang="scss">
@import "@/assets/styles/common.scss";
$url: '../../assets/images/';

.message-page{
    position: relative;
    width: 100%;
    height: 100vh;
    background: #fff;
    overflow: hidden;
    z-index: 1000;

    .back-btn{
        position: fixed;
        top: 55px;
        right: 5px;
        background-color: rgba(0,0,0,0.5);
        border-radius: 50%;
        padding: 7px;

        .iconfont{
            color: #fff;
            font-size: 20px;
        }
    }

    .loading{
        position: fixed;
        top: 45%;
        left: 50%;
        transform: translateX(-50%);
        // background-color: rgba(255,255,255,0.8);
        width: 50px;
        height: 50px;
        text-align: center;

        .loader{
            border-color: var(--color-text);
            border-bottom-color: transparent;
        }

        .iconfont{
            display: inline-block;
            font-size: 24px;
            color: var(--color-text);
            margin-top: 13px;
        }
    }

    .agent-panel{
        margin: 50px 15px 20px;
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(151,151,151,.5);

        .avatar{
            width: 50px;
            height: 50px;
            img{
                width: 100%;
            }
        }
        .agent{
            padding-left:10px;
            .name{
                font-size: 14px;
                color: #000;
            }
            .tips{
                margin-top: 5px;
                font-size: 10px;
                color: #858585;
            }
        }
    }

    .msg-list{
        margin: 25px;
        height: calc(100vh - 355px);
        overflow-y: scroll;

        .item{
            margin-bottom: 15px;
            .question{
                font-size: 14px;
                margin-bottom: 5px;
                span{
                    display: inline-block;
                    color: #fff;
                    background: rgba(164,134,104,.5);
                    border-radius: 4px;
                    padding: 4px;
                }
            }
            .answer{
                font-size: 12px;
                span{
                    float: left;
                    font-size: 14px;
                    display: inline-block;
                    color: #fff;
                    background: #141F2A;
                    border-radius: 14px 14px 0 14px;
                    padding: 4px;
                }
                .content{
                    margin-left: 26px;
                    .time{
                        color: #858585;
                        line-height: 2.2;
                    }
                    .box{
                        background: rgba(164,134,104,.1);
                        padding: 6px 6px 15px;
                        border-radius: 4px;
                    }
                }
            }
        }
    }

    .form{
        position: absolute;
        margin: 15px;
        bottom: 0px;
        border-top:1px solid #979797;
        padding-top: 10px;
        width: calc(100% - 30px);

        .col{
            margin: 0 5px 10px;
        }

        .form-group{
            display: flex;
            align-items: center;

            label{
                width: 40px;
                margin-left: 10px;
            }

            input{
                font-size: 12px;
                width: 115px;
                padding: 4px 5px;
                border:1px solid #141F2A;
                border-radius: 3px;
            }
        }
        textarea{
            width: 100%;
            font-size: 12px;
            padding: 5px;
            background: rgba(164, 134, 104, 0.14);
            border-radius: 3px;
            border: 1px solid #141F2A;
        }
        .text-center{
            text-align: center;
        }
        .post-btn{
            display: inline-block;
            font-size: 14px;
            color: #fff;
            padding: 8px 30px;
            background: #A48668;
            border-radius: 18px;
            margin: 0 auto;

            &:active{
                background: #141F2A;
            }
        }
    }

}
</style>

