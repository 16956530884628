<template>
    <div class="agentlist">
        <router-link class="back-btn" to="/zone"><i class="iconfont icon-lujing"></i></router-link>
        <div class="logo"></div>
        <div class="loading" v-if="loading">
            <div class="loader"></div><i class="iconfont icon-hudongquwei"></i>
        </div>
        <div class="list">
            <div class="item" v-for="item in list" :key="item.id">
                <div class="avatar">
                    <img :src="item.avatar">
                </div>
                <div class="agent">
                    <div class="name">{{item.name}}</div>
                    <div class="tips">最专业的分析建议，给到您</div>
                </div>
                <div class="consult">
                    <a href="javascript:;" @click="clickMsg(item)">
                        <span class="iconfont icon-message"></span>
                        <span>咨询</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "AgentList",
    data(){
        return{
            loading: true,
            list:[]
        }
    },
    mounted(){
        this.getData()
    },
    methods:{
        getData(){
            this.$http.get('user/agentlist').then((response)=>{
                console.log(response)
                this.loading = false
                this.list = response.data.list
            })
        },
        clickMsg(item){
            this.$router.push('/message/'+item.id)
        }
    }
}
</script>
<style lang="scss">
@import "@/assets/styles/common.scss";
$url: '../../assets/images/';
.agentlist{
    position: relative;
    width: 100%;
    height: 100vh;
    background: #fff;
    overflow: hidden;
    z-index: 1000;

    .back-btn{
        position: fixed;
        top: 55px;
        right: 5px;
        background-color: rgba(0,0,0,0.5);
        border-radius: 50%;
        padding: 7px;

        .iconfont{
            color: #fff;
            font-size: 20px;
        }
    }

    .logo{
        margin: 60px auto 0;
        @include bg('logo.png',126px,22px);
    }

    .loading{
        position: fixed;
        top: 45%;
        left: 50%;
        transform: translateX(-50%);
        // background-color: rgba(255,255,255,0.8);
        width: 50px;
        height: 50px;
        text-align: center;

        .loader{
            border-color: var(--color-text);
            border-bottom-color: transparent;
        }

        .iconfont{
            display: inline-block;
            font-size: 24px;
            color: var(--color-text);
            margin-top: 13px;
        }
    }

    .list{
        margin: 20px 20px;
        height: calc(100vh - 120px);
        overflow-y: scroll;

        .item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid rgba(151,151,151,.5);

            .avatar{
                width: 80px;
                img{
                    width: 70px;
                    height: 70px;
                    border: 1px solid #000;
                    border-radius: 50%;
                }
            }
            .agent{
                width: 180px;
                .name{
                    font-size: 14px;
                    color: #000;
                }
                .tips{
                    margin-top: 5px;
                    font-size: 10px;
                    color: #858585;
                }
            }
            .consult{
                text-align: center;
                .iconfont{
                    margin-top: -5px;
                    font-size: 18px;
                }
                span{
                    display: block;
                    margin-top: 5px;
                }
            }
        }
    }
}
</style>

